import start from 'assets/js/main';
import pubsub from 'pubsub.js';
import moment from 'moment';
import { pubsubNamespace } from 'components/vendor/perform/utils';
import { changePageData } from 'components/vendor/perform/localization';

require('pages/livescore/index/style.scss');
require('pages/livescore/index/ga-events');

require('widgets/livescore');
require('widgets/livescore/style.scss');

require('widgets/nesine/most-played-coupons');

require('widgets/stencilbar/livescore');
require('widgets/iddaa/markets');
require('widgets/iddaa/button');
require('widgets/article/latest-news');

const classPage = 'page-livescore-index';
const selectorPage = `.${classPage}`;
const selectorStencilbar = '.widget-stencilbar-livescore';
const selectorDateslider = '.widget-dateslider';
const selectorLivescore = '.widget-livescore';

const $context = $(selectorPage);
const settings = $context.data('settings');
const stencilbarContext = $context.find(selectorStencilbar).get(0);
const datesliderContext = $context.find(selectorDateslider).get(0);
const livescoreContext = $context.find(selectorLivescore).get(0);

const stencilbarNamespace = pubsubNamespace(stencilbarContext);
const livescoresNamespace = pubsubNamespace(livescoreContext);
const datesliderNamespace = pubsubNamespace(datesliderContext);

const SPORTS = {
  SOCCER: 'Soccer',
  BASKETBALL: 'Basketball',
  TENNIS: 'Tennis',
};

const TABS = {
  soccer: SPORTS.SOCCER,
  basketball: SPORTS.BASKETBALL,
  tennis: SPORTS.TENNIS,
};

const eventTimeouts = {};

/**
 * Publish delayed pubsub event
 * @param {string} eventName - event name
 * @param {Array} params - event parameters
 */
function publishLater(eventName, params) {
  if (eventTimeouts[eventName]) {
    clearTimeout(eventTimeouts[eventName]);
  }

  eventTimeouts[eventName] = setTimeout(() => {
    pubsub.publish(eventName, params);
  }, 500);
}

pubsub.subscribe(`${stencilbarNamespace}/sport-change`, (eventContext, newSports) => {
  if (eventContext === stencilbarContext) {
    const sportName = newSports.length === 1 ? newSports[0] : 'default';
    const pageData = settings.urls[sportName];

    updateUrl(pageData.url, pageData.title, pageData.description);
    publishLater(`${livescoresNamespace}/sport-change`, [
      livescoreContext,
      newSports.map(sport => TABS[sport]),
    ]);
  }
});

pubsub.subscribe(`${stencilbarNamespace}/order-change`,
  (eventContext, order) => {
    if (eventContext !== stencilbarContext) {
      return;
    }

    publishLater(`${livescoresNamespace}/order-change`, [livescoreContext, order]);
  }
);

['live-now', 'favourite', 'iddaa', 'live-betting', 'sound'].forEach(type => {
  pubsub.subscribe(`${stencilbarNamespace}/${type}-on-off`,
    (eventContext, value, state) => {
      if (eventContext !== stencilbarContext) {
        return;
      }

      publishLater(`${livescoresNamespace}/${type}-change`, [
        livescoreContext,
        value,
        state,
      ]);
    }
  );
});

pubsub.subscribe(`${livescoresNamespace}/set-defaults`,
  (eventContext, sports, order, onlyLive, onlyIddaa, onlyFavourite, onlyLiveBetting, soundOn) => {
    if (eventContext !== livescoreContext) {
      return;
    }

    pubsub.publish(`${stencilbarNamespace}/set-defaults`, [
      stencilbarContext,
      sports,
      order,
      onlyLive,
      onlyIddaa,
      onlyFavourite,
      onlyLiveBetting,
      soundOn,
    ]);
  }
);

const onDateChange = (eventContext, date) => {
  const dateNow = moment();
  const selectedDate = moment(date);
  const dateBefore = dateNow.clone().subtract(1, 'day');

  const isBeforeSeven =
    ((dateNow.hours() < 7)
    && (dateBefore.isSame(selectedDate, 'day')));

  const enabled = selectedDate.isSame(dateNow, 'day') || isBeforeSeven;
  const favouritesEnabled =
    selectedDate.isAfter(dateBefore, 'day') || isBeforeSeven;

  pubsub.publish(`${livescoresNamespace}/before-date-change`, [livescoreContext, date]);

  pubsub.publish(`${livescoresNamespace}/set-favourite-state`, [
    livescoreContext,
    favouritesEnabled,
  ]);
  pubsub.publish(`${stencilbarNamespace}/set-favourite-state`, [
    stencilbarContext,
    favouritesEnabled,
  ]);
  pubsub.publish(`${stencilbarNamespace}/set-live-now-state`, [stencilbarContext, enabled]);

  pubsub.publish(`${livescoresNamespace}/date-change`, [livescoreContext, date]);
};

pubsub.subscribe(`${datesliderNamespace}/date-change`, onDateChange);

['iddaa', 'live-betting'].forEach(key => {
  pubsub.subscribe(`${livescoresNamespace}/${key}-state-change`,
    (eventContext, enabled) => {
      if (eventContext !== livescoreContext) {
        return;
      }

      pubsub.publish(`${stencilbarNamespace}/set-${key}-state`, [
        stencilbarContext,
        enabled,
        false,
      ]);
    }
  );
});


/**
 * Update page url and title
 * @param {string} url - page url
 * @param {string} title - page title
 * @param {string} description - page description
 */
function updateUrl(url, title, description) {
  if (window.location.search && url.indexOf('?') === -1) {
    url += window.location.search;
  }

  changePageData(
    {
      title,
      url,
      description,
    },
    true
  );
}

start();

const today = moment();

if (today.hour() < 7) {
  today.subtract(1, 'd');

  const date = today.format('YYYY-MM-DD');

  onDateChange(datesliderContext, date);
  pubsub.publish(`${datesliderNamespace}/set-date`, [datesliderContext, date]);
}

pubsub.publish(`${livescoresNamespace}/sport-change`, [
  livescoreContext,
  settings.activeTab.map(sport => TABS[sport]),
]);
