import { pubsubNamespace } from 'components/vendor/perform/utils';
import { error as logError } from 'components/vendor/perform/core';
import { markAddedBets } from 'components/bet-slip';
import common from 'widgets/iddaa';
import pubsub from 'pubsub.js';

import 'views/partials/widget-base/style.scss';
import 'widgets/iddaa/markets/style.scss';
import iddaa from 'widgets/livescore/filter/iddaa';

const OUTCOME_UPDATE_PRE_DELAY = 5 * 60 * 1000; // five minutes
const OUTCOME_UPDATE_LIVE_DELAY = 60 * 1000; // one minute
const MATCH_STATUS_FIXTURE = 'Fixture';
const MATCH_STATUS_PLAYING = 'Playing';

const CONFIG_TYPE_LIVE = 'live';
const ACTIVE_STATE = 'active';

const SUSPENDED_STATE = -2;
const PAUSED_STATE = -1;
const CLOSED_STATE = 0;
const OPEN_STATE = 1;
const NOT_CONF_STATE = 2;
const RESULTED_STATE = 3;
const CONFIRMED_STATE = 4;

/**
 * Iddaa markets widget module
 * @param {Object} context - context object
 * @param {Object} settings - settings object
 * @param {string} settings.url - ajax html endpoint
 * @param {string} settings.updateUrl - ajax json endpoint
 * @param {boolean} settings.initialized - true if widget was loaded in sync mode
 * @param {string} settings.matchStatus - match status
 * @param {string} settings.configType - config type
 * @param {string} settings.mobileUrlTemplate - mobile URL template
 * @param {string} settings.desktopUrlTemplate - desktop URL template
 */
export default function (context, settings) {
  const $context = $(context);
  const eventNamespace = pubsubNamespace(context);
  const classWidget = 'widget-iddaa-markets';
  const classWidgetHidden = `${classWidget}--hidden`;
  const configType = settings.configType;
  const updateForStatuses = [MATCH_STATUS_FIXTURE];
  let matchStatus = settings.matchStatus;
  let initialized = !!settings.initialized;
  const iddaaId = settings.configType === 'live' ? settings.iddaaEventId.code : '';
  let dataLoaded = false;
  let loadingIsInProgress = false;
  let marketsLoaded = false;
  let firstOpen = false;
  let updateTimeout;
  let initSubscription;
  let roomsWaitingToJoin = {};
  let isSocketReady = false;
  let desktopUrlTemplate = settings.desktopUrlTemplate;
  let mobileUrlTemplate = settings.mobileUrlTemplate;

  common(context);

  if (configType === CONFIG_TYPE_LIVE) {
    updateForStatuses.push(MATCH_STATUS_PLAYING);
  }

  /**
   * Toggle iddaa markets layer
   * @param {boolean} state - state; true to add hidden class, false to remove it
   */
  function toggleIddaaMarkets(state) {
    if (state !== undefined) {
      $context.toggleClass(classWidgetHidden, state);
    } else {
      $context.toggleClass(classWidgetHidden);
    }
  }

  /**
   * Schedule outcomes update
   */
  function scheduleUpdate() {
    if (updateTimeout) {
      clearTimeout(updateTimeout);
    }

    if (updateForStatuses.indexOf(matchStatus) === -1) {
      return;
    }

    if (configType === CONFIG_TYPE_LIVE) {
      roomsWaitingToJoin['nesine'] = 1;
      joinRooms();
      // TODO Nesine room listener should be initialized here
      return;
    }

    const delay = configType !== CONFIG_TYPE_LIVE ?
      OUTCOME_UPDATE_PRE_DELAY : OUTCOME_UPDATE_LIVE_DELAY;
    const callback = marketsLoaded ? updateOutcomes : getIddaaMarkets;

    updateTimeout = setTimeout(callback, delay);
  }

  /**
   * Update outcomes fetching json from backend
   */
  function updateOutcomes(socketData) {
    if (socketData) {
      if (socketData.length && socketData.length > 0) {
        const updates = socketData;
        for (let i = 0; i < updates.length; i++) {
          const currentUpdate = updates[i];
          const marketContext = context
            .querySelector(`[data-market-id="${currentUpdate.marketId}"]`);
          if (!marketContext) {
            continue;
          }

          if (currentUpdate.status !== null && currentUpdate.status !== undefined) {
            const $marketContext = $(marketContext);
            switch (currentUpdate.status) {
              case CLOSED_STATE:
                $marketContext.remove();
                break;
              case SUSPENDED_STATE:
              case PAUSED_STATE:
                $marketContext.toggleClass(`${classWidget}__option--locked`, true);
                marketContext
                  .querySelectorAll(`.${classWidget}__value`).forEach(el => {
                    el.innerText = '';
                  });
                break;
              default:
                $marketContext.toggleClass(`${classWidget}__option--locked`, false);
                marketContext
                  .querySelectorAll(`.${classWidget}__value`).forEach(el => {
                    el.innerText = ' - ';
                  });
                break;
            }
            continue;
          }

          const outcomeContext = marketContext
            .querySelector(`[data-outcome-no="${currentUpdate.outcomeNo}"]`);
          if (!outcomeContext) {
            continue;
          }


          const labelContext = outcomeContext.querySelector(`.${classWidget}__label`);
          if (!labelContext) {
            continue;
          }

          const valueContext = outcomeContext.querySelector(`.${classWidget}__value`);
          if (!valueContext) {
            continue;
          }

          const linkContext = outcomeContext.querySelector('a');

          const valueVersion = valueContext.getAttribute('data-version');
          if (valueVersion && valueVersion >= currentUpdate.version) {
            continue;
          } else {
            valueContext.setAttribute('data-version', currentUpdate.version);
            const mobileUrl = mobileUrlTemplate.replaceAll('TEMPiddaaMarketIdTEMP', currentUpdate.marketId).replaceAll('TEMPpositionTEMP', currentUpdate.outcomeNo).replaceAll('TEMPoddTEMP', currentUpdate.newOdd);
            const desktopUrl = desktopUrlTemplate.replaceAll('TEMPiddaaMarketIdTEMP', currentUpdate.marketId).replaceAll('TEMPpositionTEMP', currentUpdate.outcomeNo).replaceAll('TEMPoddTEMP', currentUpdate.newOdd);
            linkContext.setAttribute('data-mobile-href', mobileUrl);
            linkContext.setAttribute('href', desktopUrl);
          }

          const $labelContext = $(labelContext);
          const $valueContext = $(valueContext);
          valueContext.innerText = currentUpdate.newOdd;
          const $marketContext = $(marketContext);
          $marketContext.toggleClass(`${classWidget}__option--locked`, false);
          if (parseFloat(currentUpdate.previousOdd) < parseFloat(currentUpdate.newOdd)) {
            $labelContext.toggleClass(`${classWidget}__option--increase`);
            $valueContext.toggleClass(`${classWidget}__option--increase`);
          } else {
            $labelContext.toggleClass(`${classWidget}__option--decrease`);
            $valueContext.toggleClass(`${classWidget}__option--decrease`);
          }
        }
      }

      return;
    }

    const eventArgs = [context, settings];
    pubsub.publish(`${eventNamespace}/update/before`, eventArgs);
    fetch(settings.updateUrl)
      .then(response => response.json())
      .then(response => {
        if (response.status !== 'success') {
          logError('widget/iddaa/markets:error', response.data);
          scheduleUpdate();
          return;
        }

        const { markets } = response.data;
        const marketIds = Object.keys(markets);
        matchStatus = response.data.matchStatus;

        eventArgs.push(response.data);

        marketIds.some(marketId => {
          const marketContext = context.querySelector(`[data-market="${marketId}"]`);

          if (!marketContext) {
            marketsLoaded = false;
            return true;
          }

          const { outcomes, code, mbc } = markets[marketId];

          if (!hasAnyActiveOutcome(Object.values(outcomes))) {
            marketContext.remove();
            return false;
          }

          [
            [
              code, `${classWidget}__iddaa-code`,
            ],
            [
              mbc, `${classWidget}__mbc`,
            ],
          ].forEach(([value, elementClass]) => {
            const valueContext = marketContext.querySelector(`.${elementClass}`);
            valueContext.innerText = value || '';
            valueContext.classList.toggle(`${elementClass}--hidden`, !value);
          });

          Object.keys(outcomes).some(shortcode => {
            const outcomeContext = marketContext
              .querySelector(`[data-shortcode="${shortcode}"]`);

            if (!outcomeContext) {
              marketsLoaded = false;
              return true;
            }

            const { handicap, state, label } = outcomes[shortcode];
            const labelContext = outcomeContext.querySelector(`.${classWidget}__label`);

            if (state !== ACTIVE_STATE) {
              outcomeContext.remove();
              return false;
            }

            labelContext.innerText = handicap || label;

            labelContext.classList
              .toggle(`${classWidget}__label--with-handicap`, handicap);

            return false;
          });

          return false;
        });

        pubsub.publish(`${eventNamespace}/update/complete`, eventArgs);

        if (marketsLoaded && marketIds.length > 0) {
          scheduleUpdate();
        } else {
          getIddaaMarkets();
        }
      }).catch(error => {
        logError('widget/iddaa/markets:error', error);
        eventArgs.push(error);
        pubsub.publish(`${eventNamespace}/update/fail`, eventArgs);
        scheduleUpdate();
      });
  }

  /**
   * Checks if is there any active outcome in Outcomes array
   * @param {[]} outcomes - outcomes;
   */
  function hasAnyActiveOutcome(outcomes) {
    for (let i = 0; i < outcomes.length; i++) {
      if (outcomes[i].state === ACTIVE_STATE) {
        return true;
      }
    }
    return false;
  }

  /**
   * Fetches Iddaa market for specified Iddaa number
   */
  function getIddaaMarkets() {
    pubsub.publish(`${eventNamespace}/ajax/before`, [context]);
    toggleIddaaMarkets(false);
    loadingIsInProgress = true;

    $.ajax({
      url: settings.url,
    })
      .done(response => {
        if (response.status === 'success') {
          $context.html(response.data.html);
          matchStatus = response.data.matchStatus;
          pubsub.publish(`${eventNamespace}/ajax/complete`, [context, response.data]);
          dataLoaded = true;
          init();
        } else {
          logError('widget/iddaa/markets:error:', response.data);
          pubsub.publish(`${eventNamespace}/ajax/fail`, [context, response.data]);
        }
      })
      .fail((xhr, status, error) => {
        if (status !== 'abort') {
          logError('widget/iddaa/markets:error:', xhr, status, error);
          pubsub.publish(`${eventNamespace}/ajax/fail`, [context, { xhr, status, error }]);
        }
      })
      .always(() => {
        pubsub.publish(`${eventNamespace}/ajax/after`, [context]);
      });
  }

  /**
   * Init widget
   */
  function init() {
    marketsLoaded = $context.find('[data-market]').length > 0;
    markAddedBets(context);
    scheduleUpdate();
    $context.find('.widget-iddaa-markets__arrow').on('click', function(e) {
      const $this = $(this);
      $this.toggleClass('down');
      $this.closest('div').find('.widget-iddaa-markets__option-list.type2').toggleClass('hidden');
    });
    $context.find('.widget-iddaa-markets__option-list:not(.type2) .widget-iddaa-markets__option').on('click', function(e) {
      const $this = $(this);
      $this.closest('.widget-base.widget-iddaa-markets__market').find('.widget-iddaa-markets__arrow').toggleClass('down');
      $this.closest('div').find('.widget-iddaa-markets__option-list.type2').toggleClass('hidden');
    });
  }

  const toggleSubscription = pubsub.subscribe(`${eventNamespace}/toggle-iddaa-layer`,
    eventContext => {
      if (eventContext !== context) {
        toggleIddaaMarkets(true);
        return;
      }

      if (dataLoaded) {
        toggleIddaaMarkets();
        return;
      }

      if (loadingIsInProgress && firstOpen) {
        toggleIddaaMarkets();
      }

      firstOpen = true;
    });

  if (!initialized) {
    initSubscription = pubsub.subscribe(`${eventNamespace}/init`, eventContext => {
      if (context !== eventContext) {
        return;
      }

      initialized = true;
      getIddaaMarkets();
      pubsub.unsubscribe(initSubscription);
    });
  } else {
    init();
  }

  function joinRooms() {
    if (!isSocketReady)
      return;

    for (const roomName in roomsWaitingToJoin) {
      pubsub.publish('socket/join-room', [roomName]);
    }
    roomsWaitingToJoin = {};
  }

  let socketStartingSubscription;

  let socketDataSubscription;

  if (configType === CONFIG_TYPE_LIVE) {
    socketStartingSubscription = pubsub.subscribe('socket/starting', eventContext => {
      isSocketReady = true;
      joinRooms();
    });
    socketDataSubscription = pubsub.subscribe('socket/data', eventContext => {
      if (eventContext.data[iddaaId]) {
        updateOutcomes(eventContext.data[iddaaId].data);
      }
    });
  }


  const destroySubscription = pubsub.subscribe(`${eventNamespace}/destroy`, () => {
    pubsub.unsubscribe(initSubscription);
    pubsub.unsubscribe(toggleSubscription);
    pubsub.unsubscribe(destroySubscription);
    if (configType === CONFIG_TYPE_LIVE) {
      pubsub.unsubscribe(socketDataSubscription);
      pubsub.unsubscribe(socketStartingSubscription);
    }
  });
}
