import { loadScript, loadStyle } from 'components/assets-loader';
import { warn } from 'components/vendor/perform/core';

let loadPromise;
let options;
let resolveOptionsPromise;

const optionsPromise = new Promise((resolve) => {
  resolveOptionsPromise = resolve;
});

/**
 * Set BetSlip options
 * @param {Object} newOptions - BetSlip options
 */
export function setOptions(newOptions) {
  options = newOptions;
  resolveOptionsPromise(options);
}

/**
 * Get BetSlip options
 * @returns {Promise} - options promise
 */
export function getOptions() {
  return optionsPromise;
}

/**
 * Load BetSlip
 * @returns {Promise} - load promise
 */
export default function loadBetslip() {
  if (!loadPromise) {
    loadPromise = new Promise((resolve, reject) => {
      getOptions().then((betSlipOptions) => {
        const { assets, settings } = betSlipOptions;

        Promise.all([
          loadScript(assets.bettingWidget),
          loadScript(assets.C2GRelay),
          loadStyle(assets.registrationStyles),
        ])
          .then(() => loadScript(assets.commonTutturBundle))
          .then(() => loadScript(assets.betSlipTutturBundle))
          .then(() => {
            if (!window.BetSlip) {
              reject();
            } else {
              window.betSlip = new window.BetSlip(settings);
              resolve(window.betSlip);
            }
          })
          .catch(response => {
            warn(`Error while loading Betslip assets: ${response}`);
          });
      });
    });
  }

  return loadPromise;
}
