import { pubsubNamespace, debounce } from 'components/vendor/perform/utils';
import pubsub from 'pubsub.js';

import 'widgets/switch/style.scss';

/**
 * Switch widget module
 * @param {Object} context - context object
 */
export default function (context) {
  const classWidget = 'widget-switch';
  const selectorWidget = `.${classWidget}`;
  const selectorCheckbox = `${selectorWidget}__checkbox`;
  const classText = `${classWidget}__text`;
  const selectorText = `.${classText}`;
  const checkbox = context.querySelector(selectorCheckbox);
  const options = context.querySelectorAll(selectorText);
  const eventNamespace = pubsubNamespace(context);

  checkbox.addEventListener('change', () => {
    pubsub.publish(`${eventNamespace}/change`, [context, checkbox.checked]);
  }, false);

  pubsub.subscribe(`${eventNamespace}/set-value`, (eventContext, value) => {
    if (context !== eventContext) {
      return;
    }

    checkbox.checked = !!value;
  });

  pubsub.subscribe(`${eventNamespace}/resize`, (eventContext) => {
    if (context !== eventContext) {
      return;
    }

    setOptionWidth();
  });

  /**
   * Set option width
   */
  function setOptionWidth() {
    const state = checkbox.checked;

    Array.prototype.forEach.call(options, (option) => {
      option.style.width = '';
      checkbox.checked = !option.classList.contains(`${classText}--default`);
      option.style.width = `${option.offsetWidth}px`;
    });

    checkbox.checked = state;
  }

  window.addEventListener('load', function onLoad() {
    window.removeEventListener('load', onLoad);
    setOptionWidth();
  });

  window.addEventListener('resize', debounce(setOptionWidth, 100));
  setOptionWidth();
}
