import loadBetslip from 'widgets/bet-slip/config';

const slipParamsAttrName = 'slip-params';
const betSettingsAttrName = 'bet-settings';

const slipParamsDataName = camelCase(slipParamsAttrName);
const betSettingsDataName = camelCase(betSettingsAttrName);

const selectorBetItem = `[data-${slipParamsAttrName}][data-${betSettingsAttrName}]`;
const markedModifier = '--marked';

/**
 * Change kebab-case to camelCase
 * @param {string} text - string to change
 * @returns {string} - string changed to camelCase
 */
function camelCase(text) {
  return text.split('-')
    .map((item, index) => (index > 0 ? item.charAt(0).toUpperCase() + item.substr(1) : item))
    .join('');
}

/**
 * Get BetSlip placed bets
 * @returns {Promise} - result promise
 */
function getBetSlipBets() {
  return new Promise((resolve) => {
    loadBetslip().then(() => {
      const bets = JSON.parse(window.localStorage.getItem('betSlipBets'));
      resolve(bets);
    });
  });
}

/**
 * Use event delegation to capture all bet clicks
 * @param {Event} event - click event
 */
function delegateBetClick(event) {
  let target = event.target;
  do {
    if (target.matches(selectorBetItem)) {
      handleBetClick(target);
      return;
    }

    target = target.parentNode;
  } while (target && target !== document);
}

/**
 * Toggle bet marked modifier class
 * @param {HTMLElement} element - element to mar
 * @param {boolean} addOrRemove - add or remove marked modifier
 */
function toggleBetAddedMark(element, addOrRemove) {
  const elementClass = element.dataset.elementClass || element.classList.item(0);
  const markedClass = `${elementClass}${markedModifier}`;
  element.classList.toggle(markedClass, addOrRemove);
}

/**
 * Check if any of matches has started, if yes than remove bet data attributes
 */
function matchTimeTicker() {
  const betButtons = document.querySelectorAll(selectorBetItem);

  Array.prototype.forEach.call(betButtons, (button) => {
    const { startTime } = button.dataset[betSettingsDataName];

    if (startTime <= Date.now()) {
      button.removeAttribute(`data-${slipParamsAttrName}`);
      button.removeAttribute(`data-${betSettingsAttrName}`);
    }
  });

  setTimeout(matchTimeTicker, 10000);
}

/**
 * Check if bet was already added to BetSlip
 * @param {string} slipParams - slip params
 * @returns {Promise} - result promise
 */
export function isBetAdded(slipParams) {
  return new Promise((resolve) => {
    getBetSlipBets().then((bets) => {
      resolve(bets.some(bet =>
        slipParams === bet.bookmakers[0].slipParams
      ));
    });
  });
}

/**
 * Add bet to BetSlip
 * @param {Object} betSettings - bet settings
 * @returns {Promise} - add bet promise
 */
export function addBet(betSettings) {
  return loadBetslip().then((betSlip) => {
    betSlip.addBet(betSettings);
  });
}

/**
 * Remove bet from BetSlip
 * @param {Object} betSettings - bet settings
 * @returns {Promise} - remove bet promise
 */
export function removeBet(betSettings) {
  return loadBetslip().then((betSlip) => {
    betSlip.removeBet(betSettings);
  });
}

/**
 * Add or remove bet in BetSlip
 * @param {HTMLElement} element - clicked element
 */
export function handleBetClick(element) {
  const betSettings = JSON.parse(element.dataset[betSettingsDataName]);
  const slipParams = element.dataset[slipParamsDataName];
  const { leagueName = betSettings.leagueName } = element.dataset;
  const bookmakers = Array.isArray(betSettings.bookmakers) ?
    betSettings.bookmakers : [betSettings.bookmakers];

  Object.assign(betSettings, {
    leagueName,
    bookmakers,
  });

  isBetAdded(slipParams)
    .then((isAdded) => {
      if (isAdded) {
        removeBet(betSettings);
      } else {
        addBet(betSettings);
      }
    });
}

/**
 * Mark all already added bets
 * @param {HTMLElement} [context=document] - context to process
 */
export function markAddedBets(context = document) {
  const allBetItems = context.querySelectorAll(selectorBetItem);

  Array.prototype.forEach.call(allBetItems, (betItem) => {
    toggleBetAddedMark(betItem, false);
  });

  getBetSlipBets().then((bets) => {
    bets.forEach((bet) => {
      const slipParams = bet.bookmakers[0].slipParams;
      const selectedBetItems = context.querySelectorAll(`[data-slip-params='${slipParams}']`);

      Array.prototype.forEach.call(selectedBetItems, (betItem) => {
        toggleBetAddedMark(betItem, true);
      });
    });
  });
}

/**
 * Init default bet handler
 */
export function init() {
  document.addEventListener('click', delegateBetClick, false);

  matchTimeTicker();

  loadBetslip().then((betSlip) => {
    betSlip.setUpdatedBetCallback(() => {
      markAddedBets();
    });
  });
}
