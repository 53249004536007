import { pubsubNamespace } from 'components/vendor/perform/utils';
import pubsub from 'pubsub.js';

import 'widgets/iddaa/button/style.scss';

/**
 * Iddaa button widget module
 * @param {Object} context - context object
 * @param {Object} settings - settings object
 */
export default function (context, settings) {
  const matchId = settings.matchId;
  const selectorWidget = '.widget-iddaa-button';
  const eventNamespace = pubsubNamespace(context);

  $(document).on('click', `${selectorWidget}[data-matchId="${matchId}"]`, () => {
    pubsub.publish(`${eventNamespace}/iddaa-button-pressed/${matchId}`, [context, { matchId }]);
  });

  const destroySubscription = pubsub.subscribe(`${eventNamespace}/destroy`, () => {
    $(document).off('click', `${selectorWidget}[data-matchId="${matchId}"]`);
    pubsub.unsubscribe(destroySubscription);
  });
}
