import { applyEventsMap } from 'components/ga';

const selectorDatePicker = '.widget-dateslider__datepicker-toggle';

const selectorStencilBar = '.widget-stencilbar-livescore';
const selectorStencilBarItem = `${selectorStencilBar}__icons ${selectorStencilBar}__icon-checkbox`;
const selectorStencilBarSound = `${selectorStencilBar}__switches \
  ${selectorStencilBar}__icon-checkbox`;
const selectorTabsItem = `${selectorStencilBar}__sports-link`;

const selectorLivescore = '.widget-livescore';
const selectorMatchRow = `${selectorLivescore} .match-row`;
const selectorLivescoreCompLink = `${selectorLivescore}__competition-link,\
${selectorMatchRow}__competition`;

const selectorLivescoreMatchLink = `${selectorMatchRow}__start-time, ${selectorMatchRow}__status,\
${selectorMatchRow}__score`;
const selectorLivescoreTeamLink = `${selectorMatchRow}__team-name`;
const selectorSwitch = '.widget-switch__checkbox';

const gaEventMapping = {
  [selectorDatePicker]: {
    category: 'LiveScoreDatePicker',
    action: 'CalendarClick',
    label: 'Takvim',
  },
  [selectorTabsItem]: {
    category: 'LiveScoreMenu',
    action: 'NavClick',
  },
  [selectorStencilBarItem]: {
    category: 'LivescoreFilters',
    action: 'FilterClick',
  },
  [selectorStencilBarSound]: {
    category: 'SoundClick',
    action: 'SoundClick',
  },
  [selectorLivescoreMatchLink]: {
    category: 'LiveScoreMatches',
    action: 'MatchClick',
    label: 'MacDetay',
  },
  [selectorLivescoreCompLink]: {
    category: 'LiveScoreMatches',
    action: 'CompClick',
    label: 'Lig',
  },
  [selectorLivescoreTeamLink]: {
    category: 'LiveScoreMatches',
    action: 'TeamClick',
    label: 'Takim',
  },
  [selectorSwitch]: {
    category: 'LeagueTimeSwitch',
    action: 'SortClick',
    label: 'LigSaat',
  },
};
const filterStatuses = {};
let switchStatus = $(selectorSwitch).prop('checked');

applyEventsMap(gaEventMapping, (selector, $item, eventData) => {
  let pushEventEnabled = true;

  switch (selector) {
    case selectorTabsItem:
      switch ($item.data('sport')) {
        case 'tennis':
          eventData.label = 'Tenis';
          break;
        case 'soccer':
          eventData.label = 'Futbol';
          break;
        case 'basketball':
          eventData.label = 'Basketbol';
          break;
        default:
          return false;
      }
      break;
    case selectorStencilBarItem:
    case selectorStencilBarSound:
      eventData.label = $item.data('gaLabel');
      pushEventEnabled = filterStatuses[eventData.label] !== $item.prop('checked');
      filterStatuses[eventData.label] = $item.prop('checked');
      break;
    case selectorSwitch:
      pushEventEnabled = switchStatus !== $item.prop('checked');
      switchStatus = $item.prop('checked');
      break;
    default:
      return true;
  }

  return pushEventEnabled;
}, 'click.ga change.ga');
