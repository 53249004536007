const stylePromises = {};
const scriptPromises = {};

/**
 * loads style by url
 * @param {string} url - style url to load
 * @returns {Promise} - style load promise
 */
function loadStyle(url) {
  if (stylePromises[url]) {
    return stylePromises[url];
  }

  /* eslint-disable new-cap */
  const loadDeferred = $.Deferred();
  /* eslint-enable new-cap */
  stylePromises[url] = loadDeferred.promise();

  const styleLink = document.createElement('link');
  styleLink.onload = () => {
    loadDeferred.resolve();
  };
  styleLink.rel = 'stylesheet';
  styleLink.href = url;
  document.getElementsByTagName('head')[0].appendChild(styleLink);

  return stylePromises[url];
}

/**
 * loads script
 * @function loadScript
 * @param {string} url - script url to load
 * @param {Object} params - script config if needed
 * @returns {Promise} - script load promise
 */
function loadScript(url, params = {}) {
  if (scriptPromises[url]) {
    return scriptPromises[url];
  }

  /* eslint-disable new-cap */
  const scriptDeferred = $.Deferred();
  /* eslint-enable new-cap */
  scriptPromises[url] = scriptDeferred.promise();
  const config = Object.assign({
    type: 'text/javascript',
    async: true,
  }, params);

  const scriptLink = document.createElement('script');
  scriptLink.type = config.type;
  scriptLink.async = config.async;
  scriptLink.src = url;
  scriptLink.onload = () => {
    scriptDeferred.resolve();
  };
  document.getElementsByTagName('head')[0].appendChild(scriptLink);

  return scriptPromises[url];
}

export {
  loadStyle,
  loadScript,
};
