import { pubsubNamespace } from 'components/vendor/perform/utils';
import { session as storage } from 'components/vendor/perform/storage';
import { checkPlayability } from 'components/audio-player';
import { delegateEvent, forEachElement } from 'components/utils';
import pubsub from 'pubsub.js';
import 'widgets/stencilbar/livescore/style.scss';

require('widgets/switch');
require('widgets/dateslider');

const ORDER_COMP = 'comp';
const ORDER_TIME = 'time';
const STORAGE_FILTERS_STATE_KEY = 'filtersState';

/**
 * Stencilbar for livescore widget module
 * @param {HTMLElement} context - context object
 * @param {Object} settings - widget settings
 * @param {string[]} settings.activeSport - activeSport
 * @param {[[]]} settings.sports - sport groups
 */
export default function (context, { activeSport, sports }) {
  const classWidget = 'widget-stencilbar-livescore';
  const classIcon = `${classWidget}__icon`;
  const selectorIcon = `.${classIcon}`;
  const selectorCheckbox = `${selectorIcon}-checkbox`;
  const classIconDisabled = `${classIcon}--disabled`;
  const classSwitch = 'widget-switch';
  const selectorSwitch = `.${classSwitch}`;
  const classSportItemActive = `${classWidget}__sports-item--active`;
  const classSportItemSingle = `${classWidget}__sports-item--single`;
  const selectorSportLink = `.${classWidget}__sports-link`;
  const sportLinks = context.querySelectorAll(selectorSportLink);
  const switchContext = context.querySelector(selectorSwitch);
  const switchEventNamespace = pubsubNamespace(switchContext);
  const icons = context.querySelectorAll(selectorIcon);
  const eventNamespace = pubsubNamespace(context);

  let filtersState = storage.get(STORAGE_FILTERS_STATE_KEY);

  /**
   * Publish information when switch's state changes
   * @param {HTMLElement} eventContext - switch widget context
   * @param {boolean} value - alt/default flag
   */
  function onSwitchChange(eventContext, value) {
    if (eventContext !== switchContext) {
      return;
    }

    const orderBy = (value === false) ? ORDER_COMP : ORDER_TIME;
    saveFilterStateToStorage('order', orderBy);
    pubsub.publish(`${eventNamespace}/order-change`, [context, orderBy]);
  }

  /**
   * Set default switch's state on page load
   * @param {string} orderBy - default order of livescores
   */
  function setSwitchOnLoad(orderBy) {
    pubsub.publish(`${switchEventNamespace}/set-value`, [switchContext, orderBy !== ORDER_COMP]);
  }

  /**
   * Run callback for each icon
   * @param {Function} callback - callback to call
   */
  function forEachIcon(callback) {
    Array.prototype.forEach.call(icons, (icon) => {
      const checkbox = icon.querySelector(selectorCheckbox);
      const type = checkbox.dataset.type;

      callback(type, checkbox, icon);
    });
  }

  /**
   * Set filters state based on data saved in the storage
   */
  function setFiltersFromStorage() {
    if (!filtersState) {
      return;
    }

    setSwitchOnLoad(filtersState.order);
    onSwitchChange(switchContext, filtersState.order !== ORDER_COMP);

    forEachIcon((type, checkbox) => {
      const state = Boolean(filtersState[type]);

      if (type === 'sound' && state) {
        checkPlayability().then((allowed) => {
          checkbox.checked = allowed;
          pubsub.publish(`${eventNamespace}/${type}-on-off`, [
            context,
            checkbox.checked,
            state,
          ]);
        });
      } else {
        checkbox.checked = !checkbox.disabled ? state : checkbox.checked;
        pubsub.publish(`${eventNamespace}/${type}-on-off`, [
          context,
          checkbox.checked,
          state,
        ]);
      }
    });
  }

  /**
   * Save filter state to storage
   * @param {string} name - filter's state name
   * @param {string|boolean} value - filter's new value
   */
  function saveFilterStateToStorage(name, value) {
    filtersState = filtersState || {};
    filtersState[name] = value;
    storage.set(STORAGE_FILTERS_STATE_KEY, filtersState);
  }

  pubsub.subscribe(`${eventNamespace}/set-defaults`,
    (eventContext, order, liveNow, iddaa, favourite, liveBetting, sound) => {
      if (eventContext !== context) {
        return;
      }

      if (filtersState) {
        return;
      }

      filtersState = {
        order,
        'live-now': liveNow,
        iddaa,
        favourite,
        'live-betting': liveBetting,
        sound,
      };
      storage.set(STORAGE_FILTERS_STATE_KEY, filtersState);
    }
  );

  pubsub.subscribeOnce('core/rendered', setFiltersFromStorage);
  pubsub.subscribe(`${switchEventNamespace}/change`, onSwitchChange);

  forEachIcon((type, checkbox, icon) => {
    icon.addEventListener('click', () => {
      saveFilterStateToStorage(type, checkbox.checked);
      pubsub.publish(`${eventNamespace}/${type}-on-off`, [
        context,
        checkbox.checked,
      ]);
    }, false);

    pubsub.subscribe(`${eventNamespace}/set-${type}-state`,
      (eventContext, state, publishChange = true) => {
        if (eventContext !== context) {
          return;
        }

        checkbox.checked = state ? filtersState[type] : false;
        checkbox.disabled = !state;
        icon.classList.toggle(classIconDisabled, !state);

        if (publishChange) {
          pubsub.publish(`${eventNamespace}/${type}-on-off`, [
            context,
            checkbox.checked,
          ]);
        }
      }
    );
  });

  /**
   * Toggle selected sport
   * @param {string} sportName - sport name to toggle
   */
  function toggleSport(sportName) {
    const sportIndex = activeSport.indexOf(sportName);
    if (sportIndex !== -1) {
      if (activeSport.length > 1) {
        activeSport.splice(sportIndex, 1);
      }
    } else {
      const newGroup = sports.filter(
        (group) => group.indexOf(sportName) !== -1
      ).pop();
      const currentGroup = sports.filter(
        (group) => group.filter(sport => activeSport.indexOf(sport) !== -1).length
      ).pop();

      if (newGroup === currentGroup) {
        activeSport.push(sportName);
      } else {
        activeSport = [sportName];
      }
    }
  }

  delegateEvent(context, 'click', selectorSportLink, function onSportClick() {
    toggleSport(this.dataset.sport);

    forEachElement(sportLinks, (link) => {
      const item = link.parentNode;

      item.classList.remove(classSportItemSingle, classSportItemActive);

      if (activeSport.indexOf(link.dataset.sport) !== -1) {
        item.classList.add(classSportItemActive);

        if (activeSport.length === 1) {
          item.classList.add(classSportItemSingle);
        }
      }
    });

    pubsub.publish(`${eventNamespace}/sport-change`, [context, activeSport]);
  });
}
