import moment from 'moment';
import pubsub from 'pubsub.js';
import { onRAF, pubsubNamespace } from 'components/vendor/perform/utils';
import { breakpoint } from 'components/vendor/perform/responsive';
import { html, render } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat.js';
import { classMap } from 'lit-html/directives/class-map.js';
import {
  delegateEvent,
  closest,
  queryWidget,
  passiveSupported,
  offsetTop,
} from 'components/utils';

import 'widgets/dateslider/style.scss';

require('widgets/datepicker');
require('moment/locale/tr');
require('@webcomponents/template');

export const MODE_DAY = 'day';
export const MODE_WEEK = 'week';

const dayModeTo = '580';

const widgetBase = 'widget-dateslider';
const dateFormat = 'YYYY-MM-DD';

/**
 * Get id for passed day
 * @param {moment} day - day
 * @returns {string} - day id
 */
function getDayId(day) {
  return `${widgetBase}-day-${day.format(dateFormat)}`;
}

/**
 * Create Template result to render a day
 * @param {moment} day - day to render
 * @param {boolean} selected - set true to mark day as selected
 * @returns {TemplateResult} - Template to render
 */
function getDayTemplate(day, selected = false) {
  const elementBase = `${widgetBase}__date`;
  const classes = {
    [elementBase]: true,
    [`${elementBase}--selected`]: selected,
  };

  return html`
    <li class=${classMap(classes)} id="${getDayId(day)}" data-date="${day.format(dateFormat)}">
      <span class="widget-dateslider__day-name">${day.format('ddd')}</span>
      <span class="widget-dateslider__day-date">${day.format('DD/MM')}</span>
    </li>
  `;
}

/**
 * Get date slider widget mode depending on current breakpoint
 * @returns {string} - mode of widget
 */
function getMode() {
  return breakpoint('to', dayModeTo) ? MODE_DAY : MODE_WEEK;
}

/**
 * Generate list of days to render
 * @param {moment} from - start date
 * @param {moment} to - end date
 * @param {moment} selected - selected date
 * @returns {Array} - list of days to render
 */
function buildDaysArray(from, to, selected) {
  const days = [];

  to = to.clone().add(1, 'd');

  while (!from.isSame(to, 'day')) {
    days.push({
      date: from.clone(),
      selected: selected.isSame(from, 'day'),
    });
    from.add(1, 'd');
  }

  return days;
}

/**
 * Get current translateX of element
 * @param {HTMLElement} element - element from which get current translateX
 * @returns {number} - Current translateX value
 */
function getCurrentTranslateX(element) {
  const currentTransform = window.getComputedStyle(element)
    .getPropertyValue('transform');

  if (currentTransform === 'none') {
    return 0;
  }

  return parseFloat(currentTransform.match(/matrix\((?:[^,]+,){4}\s*([^,]+)/)[1]);
}

/**
 * Date slider widget
 * @param {HTMLElement} context - widget context
 */
export default function (context) {
  moment.locale(window.settings.localization.languageCode);

  const classDatepickerOpen = `${widgetBase}__datepicker--open`;
  const selectorDatepickerToggle = `.${widgetBase}__datepicker-toggle`;
  const selectorDatepicker = `.${widgetBase}__datepicker`;
  const selectorDate = `.${widgetBase}__date`;
  const classSlider = `${widgetBase}__slider`;
  const classSliderSlide = `${classSlider}--slide`;

  const daysList = context.querySelector(`.${widgetBase}__dates`);
  const daysSlider = context.querySelector(`.${classSlider}`);
  const datePicker = context.querySelector(selectorDatepicker);
  const datePickerWidget = queryWidget(datePicker, 'datepicker');
  const eventNamespace = pubsubNamespace(context);
  const datePickerNamespace = pubsubNamespace(datePickerWidget);
  const headerWidget = queryWidget(document.body, 'header');

  let selectedDay = moment(moment().format(dateFormat), dateFormat);
  const middleDay = selectedDay.clone();

  let mode = getMode();
  let dayWidth;
  let padding;
  let move;
  let leftBoundary = 0;
  let rightBoundary = 0;
  let currentIndex = 0;
  let duringChange = false;

  let scrollOffset = headerWidget.offsetHeight;
  let datePickerOffset = false;

  pubsub.subscribe('responsive/breakpointchange', () => {
    const newMode = getMode();

    scrollOffset = headerWidget.offsetHeight;

    if (newMode !== mode) {
      mode = newMode;
      onModeChange();
    }
  });

  /**
   * Render days list
   * @param {Array} days - days to render
   */
  function renderDays(days) {
    render(html`
      ${repeat(
        days,
          day => getDayId(day.date),
          day => getDayTemplate(day.date, day.selected)
        )}
      `,
      daysList
    );
  }

  /**
   * Mode change handler
   */
  function onModeChange() {
    move = mode === MODE_DAY ? 1 : 7;
    padding = Math.floor(move / 2);
    dayWidth = daysSlider.offsetWidth / move;

    resetDays();
  }

  /**
   * Set selected day
   * @param {moment} day - day to set as selected
   */
  function onDaySelected(day) {
    const diff = day.diff(middleDay, 'days');
    selectedDay = day.clone();
    slideDays(diff - currentIndex);

    setDatepickerDate(selectedDay);
    publishDateChange();
  }

  /**
   * Publish date-changed event
   */
  function publishDateChange() {
    pubsub.publish(`${eventNamespace}/date-change`, [
      context,
      selectedDay.format(dateFormat),
    ]);
  }

  /**
   * Move by count of frames
   * @param {number} [daysCount=0] - negative for prev positive for next, leave 0 to keep
   * current state
   * @param {boolean} [disableTransition=false] - set false to disable transition
   * @returns {Promise} - slide animation start promise
   */
  function slideDays(daysCount = 0, disableTransition = false) {
    return new Promise((resolve) => {
      const prevIndex = currentIndex;
      let startTranslateX = false;

      duringChange = true;

      if (daysCount > 0) {
        rightBoundary += daysCount;
        currentIndex += daysCount;
      } else if (daysCount < 0) {
        leftBoundary -= daysCount;
        currentIndex += daysCount;

        startTranslateX = getCurrentTranslateX(daysList) -
          ((leftBoundary + prevIndex) * dayWidth);
      }

      const days = buildDaysArray(
        middleDay.clone().subtract(leftBoundary + padding, 'd'),
        middleDay.clone().add(rightBoundary + padding, 'd'),
        selectedDay
      );

      const newTranslateX = -(currentIndex + leftBoundary) * dayWidth;

      daysList.style.transition = 'none';
      daysSlider.classList.add(classSliderSlide);

      if (daysCount < 0) {
        renderDays(days);
      }

      const slideStartResove = () => {
        daysList.style.transition = '';
        duringChange = false;
        resolve();
      };

      onRAF(() => {
        if (startTranslateX !== false) {
          daysList.style.transform = `translate3d(${startTranslateX}px, 0, 0)`;
        }
        onRAF(() => {
          daysList.style.transform = `translate3d(${newTranslateX}px, 0, 0)`;

          if (daysCount >= 0) {
            renderDays(days);
          }

          if (!disableTransition) {
            slideStartResove();
          } else {
            onRAF(slideStartResove);
          }
        });
      });
    });
  }

  /**
   * Reset frames state
   */
  function resetDays() {
    middleDay.add(currentIndex, 'd');

    leftBoundary = 0;
    rightBoundary = 0;
    currentIndex = 0;

    slideDays(0, true).then(() => {
      daysSlider.classList.remove(classSliderSlide);
    });
  }

  /**
   * Transition end event handler for dates slider
   * @param {TransitionEvent} event - transition event
   */
  function onSlideEnd(event) {
    if (event.target !== daysList || event.propertyName !== 'transform') {
      return;
    }

    resetDays();
  }

  /**
   * Hide date picker
   */
  function hideDatepicker() {
    if (datePicker.classList.contains(classDatepickerOpen)) {
      datePicker.classList.remove(classDatepickerOpen);
    }
  }

  /**
   * Set selected day without publishing it outside
   * @param {moment} day - day to set
   */
  function setSelectedDay(day) {
    selectedDay = day.clone();
    currentIndex = selectedDay.diff(middleDay, 'days');

    resetDays();
    hideDatepicker();
  }

  /**
   * Set date in date picker
   * @param {moment} day - day to set
   */
  function setDatepickerDate(day) {
    pubsub.publish(`${datePickerNamespace}/set-date`, [
      datePickerWidget,
      day.format(dateFormat),
    ]);
  }

  /**
   * Toggle date picker
   */
  function toggleDatepicker() {
    datePicker.classList.toggle(classDatepickerOpen);
  }

  delegateEvent(context, 'click', selectorDatepickerToggle, toggleDatepicker);
  delegateEvent(context, 'click', `.${widgetBase}__arrow`, function onArrowClick() {
    if (duringChange) {
      return;
    }

    if (this.matches(`.${widgetBase}__arrow--next`)) {
      slideDays(move);
    } else {
      slideDays(-move);
    }

    if (mode === MODE_DAY) {
      onDaySelected(middleDay.clone().add(currentIndex, 'd'));
    }
  });
  delegateEvent(context, 'click', selectorDate, function onDateClick() {
    if (mode === MODE_WEEK) {
      onDaySelected(moment(this.dataset.date, dateFormat));
    } else {
      toggleDatepicker();
    }
  });

  document.body.addEventListener('click', (event) => {
    const classes = [
      selectorDatepickerToggle,
      selectorDatepicker,
    ];

    if (mode === MODE_DAY) {
      classes.push(selectorDate);
    }

    const element = closest(event.target, classes.join(','), context);

    if (!element) {
      hideDatepicker();
    }
  }, false);

  window.addEventListener('scroll', () => {
    if (!datePicker.classList.contains(classDatepickerOpen)) {
      return;
    }

    if (!datePickerOffset) {
      datePickerOffset = offsetTop(datePicker);

      setTimeout(() => {
        datePickerOffset = false;
      }, 500);
    }

    if (datePickerOffset - scrollOffset <= window.scrollY) {
      hideDatepicker();
    }
  }, passiveSupported() ? { passive: true, capture: false } : false);

  daysList.addEventListener('transitionend', onSlideEnd);

  pubsub.subscribe(`${datePickerNamespace}/date-change`, (eventContext, date) => {
    if (eventContext !== datePickerWidget) {
      return;
    }

    setSelectedDay(moment(date, dateFormat));
    publishDateChange();
  });

  pubsub.subscribe(`${datePickerNamespace}/today-clicked`, (eventContext) => {
    if (eventContext !== datePickerWidget) {
      return;
    }

    hideDatepicker();
  });

  pubsub.subscribe(`${eventNamespace}/set-date`, (eventContext, date) => {
    if (eventContext !== context) {
      return;
    }

    setSelectedDay(moment(date, dateFormat));
    setDatepickerDate(selectedDay);
  });

  pubsub.subscribe('header/open', (eventContext) => {
    scrollOffset = eventContext.offsetHeight;
  });
  pubsub.publish('header/close', () => {
    scrollOffset = 0;
  });

  onModeChange();
}
