import { pubsubNamespace, onRAF } from 'components/vendor/perform/utils';
import { forEachElement, delegateEvent } from 'components/utils';
import moment from 'moment';
import pubsub from 'pubsub.js';
import 'widgets/datepicker/style.scss';

require('moment/locale/tr');

/**
 * Datepicker widget module
 * @param {Object} context - context object
 */
export default function (context) {
  const classWidget = 'widget-datepicker';
  const classCalendar = `${classWidget}__calendar`;
  const selectorCalendar = `.${classCalendar}`;
  const classCalendarBody = `${classCalendar}-body`;
  const selectorCalendarBody = `.${classCalendarBody}`;
  const classCalendarBodyCell = `${classCalendarBody}-cell`;
  const selectorCalendarBodyCell = `.${classCalendarBodyCell}`;
  const classCalendarHeaderCell = `${classCalendar}-header-cell`;
  const selectorCalendarHeaderCell = `.${classCalendarHeaderCell}`;
  const classCalendarBodyCellNotMonthDay = `${classCalendarBodyCell}--not-month-day`;
  const classCalendarBodyCellSelectedDay = `${classCalendarBodyCell}--selected-day`;
  const classCalendarBodyCellCurrentDay = `${classCalendarBodyCell}--current-day`;
  const selectorSelector = `.${classWidget}__selector`;
  const classNav = `${classWidget}__nav`;
  const selectorNav = `.${classNav}`;
  const selectorValue = `.${classWidget}__value`;
  const selectorMonthSelector = `${selectorSelector}--month`;
  const selectorYearSelector = `${selectorSelector}--year`;
  const selectorMonthValue = `${selectorMonthSelector} ${selectorValue}`;
  const selectorYearValue = `${selectorYearSelector} ${selectorValue}`;
  const selectorButtonToday = `.${classWidget}__button--today`;
  const calendar = context.querySelector(selectorCalendar);
  const calendarBody = context.querySelector(selectorCalendarBody);
  const calendarBodyCells = context.querySelectorAll(selectorCalendarBodyCell);
  const calendarHeaderCells = context.querySelectorAll(selectorCalendarHeaderCell);
  const selectedMonth = context.querySelector(selectorMonthValue);
  const selectedYear = context.querySelector(selectorYearValue);
  const buttonToday = context.querySelector(selectorButtonToday);
  const eventNamespace = pubsubNamespace(context);
  const dateFormat = 'YYYY-MM-DD';

  moment.locale(window.settings.localization.languageCode);

  let date = moment();
  let calendarDate = date.clone();

  buttonToday.setAttribute('data-date', date.format(dateFormat));
  buttonToday.dataset.date = date.format(dateFormat);

  /**
   * Publish date change
   */
  function updateDate() {
    pubsub.publish(`${eventNamespace}/date-change`, [
      context,
      date.format(dateFormat),
    ]);
  }

  /**
   * Change datepicker selected date
   */
  function changeDate() {
    const prevDate = date;
    const prevCalendarDate = calendarDate;

    date = moment(this.dataset.date, dateFormat);

    if (!date.isSame(calendarDate, 'month')) {
      calendarDate = date.clone();
    }

    if (!date.isSame(prevDate, 'day')) {
      updateDate();
    }

    if (
      !date.isSame(prevDate, 'day') ||
      !calendarDate.isSame(prevCalendarDate, 'month')
    ) {
      fillCalendar();
    }
  }

  /**
   * Month nad year nav click handler
   */
  function handleNavClick() {
    const unit = this.parentNode.matches(selectorMonthSelector) ? 'month' : 'year';

    if (this.classList.contains(`${classNav}--previous`)) {
      calendarDate.subtract(1, unit);
    } else {
      calendarDate.add(1, unit);
    }

    fillCalendar();
  }

  /**
   * Fill calendar with correct data for selected month
   * @param {string} selectedDate - selected date in calendar
   */
  function fillCalendar(selectedDate = null) {
    if (selectedDate) {
      date = moment(selectedDate, dateFormat);
      calendarDate = date.clone();
    }

    selectedMonth.innerText = calendarDate.format('MMM');
    selectedYear.innerText = calendarDate.format('YYYY');

    const tempDate = calendarDate.clone();
    calendarBody.parentNode.removeChild(calendarBody);

    tempDate
      .subtract(tempDate.date() - 1, 'days')
      .subtract(tempDate.weekday(), 'days');

    forEachElement(calendarBodyCells, (cell) => {
      cell.innerText = tempDate.date();
      cell.setAttribute('data-date', tempDate.format(dateFormat));
      cell.dataset.date = tempDate.format(dateFormat);
      cell.classList
        .toggle(classCalendarBodyCellNotMonthDay, !tempDate.isSame(calendarDate, 'month'));
      cell.classList
        .toggle(classCalendarBodyCellSelectedDay, tempDate.isSame(date, 'day'));
      cell.classList
        .toggle(classCalendarBodyCellCurrentDay, moment().isSame(tempDate, 'day'));

      tempDate.add(1, 'day');
    });

    calendar.appendChild(calendarBody);
  }

  /**
   * Fill calendar header with correct day shortcuts
   */
  function fillCalendarHeader() {
    const localWeekDays = moment.weekdaysMin(true);

    onRAF(() => {
      forEachElement(calendarHeaderCells, (cell, index) => {
        cell.innerText = localWeekDays[index];
      });
    });
  }

  pubsub.subscribe(`${eventNamespace}/set-date`, (eventContext, selectedDate) => {
    if (context !== eventContext) {
      return;
    }

    fillCalendar(selectedDate);
  });

  delegateEvent(context, 'click', selectorCalendarBodyCell, changeDate);
  delegateEvent(context, 'click', selectorButtonToday, function onTodayClick(...args) {
    changeDate.call(this, ...args);
    pubsub.publish(`${eventNamespace}/today-clicked`, [context]);
  });
  delegateEvent(context, 'click', selectorNav, handleNavClick);

  fillCalendar();
  fillCalendarHeader();
}
